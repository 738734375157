import React from 'react';
import AutoTrackEntryPage from "./include/AutoTrackEntryPage";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import ShipmentRecordNav from "../ShipmentRecordNav";
import {ShipmentTitle} from "../ShipmentTitle";
import {useParams} from "react-router-dom";
import TgfComboBox from "../../../core/kendo/form/inputs/TgfComboBox";

const AutoTrackTab = (props) => {

    const urlParams = useParams();
    const bolNumber = parseInt(urlParams?.id);

    return (
        <FullWidthLayout
            SideNav={ShipmentRecordNav}
            title={<ShipmentTitle bolNumber={bolNumber}
                                  title={`Shipments - Autotrack - ${bolNumber ? bolNumber : 'New'}`}/>}
            className={"shipments-styles"}>

            <div style={{padding: '5px', margin: '5px'}}>
                <TgfComboBox
                    label={'Autotrack Page View'}
                    id={'page-view'}
                    name={'page-view'}
                    data={[
                        {display: 'Entry Page'}
                    ]}
                    onChange={null}
                >

                </TgfComboBox>
                <AutoTrackEntryPage></AutoTrackEntryPage>
            </div>
        </FullWidthLayout>
    )
};

export default AutoTrackTab
