import React, {useEffect, useRef, useState} from 'react';
import TgfFormComponent from "../../../../core/kendo/form/tgfFormComponent/TgfFormComponent";
import {TgfFormGrid} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfFormGrid";
import _ from "lodash";
import {useDispatch} from "react-redux";
import * as yup from "yup";
import {TgfTextBoxFormField} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfTextBoxFormField";
import {TgfComboBoxFormField} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfComboBoxFormField";
import {TgfToggleFormField} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfToggleFormField";
import {
    TgfDateOnlyPickerFormField
} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfDateOnlyPickerFormField";
import {TgfTimeInputFormField} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfTimeInputFormField";
import {TgfTextAreaFormField} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfTextAreaFormField";
import {TgfToggleGroupFormField} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfToggleGroupFormField";
import {TgfBaseFormField} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfBaseFormField";
import {useParams} from "react-router-dom";
import {TgfButtonFormField} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfButtonFormField";
import {getHeaderGrid} from "./grids/Header";
import {getCarrierInformationGrid} from "./grids/CarrierInformation";
import {getOriginPickupLocationGrid} from "./grids/OriginPickupLocation";
import {getMultiStopButtonGrid} from "./grids/MultiStopButton";
import {getDestinationDeliveryLocationGrid} from "./grids/DestinationDeliveryLocation";
import {getAdditionalCommunicationGrid} from "./grids/AdditionalCommunication";
import {getSubmitGrid} from "./grids/submitButton";
import {getAutoTrackEntryValidation} from "./validation/AutoTrackEntryValidation";

const AutoTrackEntryPageTab = (props) => {

        const urlParams = useParams();
        const bolNumber = parseInt(urlParams?.id);

        const autoTrackEntryValidation = getAutoTrackEntryValidation();

        const ref = useRef();
        const personalDispatch = useDispatch();

        const [gridList, setGridList] = useState([]);

        const removeMultiStopSection = async (index) => {


        }

        const loadFormData = async (loadValue) => {
            const result = await window.shell.gateway.getAutoTrackFormData(bolNumber);

            return result;
        };

        const handleSubmit = async (data) => {
            if (data) {
                //pass data to gateway
            }
        };

        useEffect(() => {
            setGridList([
                //Header
                getHeaderGrid(),
                //Carrier Information
                getCarrierInformationGrid(),
                //Origin Pickup Location
                getOriginPickupLocationGrid(),
                //add multi stop button
                getMultiStopButtonGrid(setGridList, ref),
                //Destination Delivery Location
                getDestinationDeliveryLocationGrid(),
                //Additional Communication
                getAdditionalCommunicationGrid(),
                //submit button
                getSubmitGrid(ref),
            ])
        }, [bolNumber]);

        // initial load
        useEffect(() => {
            ref.current?.loadForm();
            ref.current?.setIsEditMode(true);
        }, [bolNumber]);

        return (
            <TgfFormComponent
                ref={ref}
                onLoadFormData={loadFormData}
                loadFormArgument={null}
                validationSchema={autoTrackEntryValidation}
                onSubmit={handleSubmit}
                preRenderComponent={<>Loading...</>}
                processName={'auto-track-entry-page'}
                gridList={gridList}
                {...props}
            >
            </TgfFormComponent>
        )
    }
;

export default AutoTrackEntryPageTab

