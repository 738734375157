import {TgfBaseFormField} from "./TgfBaseFormField";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import React from "react";
import {Box, Grid, Tooltip} from "@material-ui/core";
import {Label} from "@progress/kendo-react-labels";
import {TgfToggleFormField} from "./TgfToggleFormField";

export class TgfToggleGroupFormField extends TgfBaseFormField {
    constructor({
                    columnWidth = 1,
                    id = 'toggle-group',
                    label = 'toggle-group',
                    toggles = [new TgfToggleFormField({})]
                }) {
        super(columnWidth);

        this.id = id;
        this.label = label;
        this.toggles = toggles;
    }

    handleCheckboxChange(e) {
        this.setValue(this.bindingValue, e.target.value);
    };

    renderField(setValue, data, validationErrors, isEditMode) {
        this.setValue = setValue;
        return <>
            <Grid container spacing={2}>
                <Grid item>
                    <Label editorId={this.id}>{this.label}</Label>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            p: 1,
                            m: 1,
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                        }}
                    >
                        {this.toggles.map((obj) => obj.renderField(setValue, data, validationErrors, isEditMode))}
                    </Box>
                </Grid>
            </Grid>
        </>
    }
}
