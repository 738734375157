export class TgfBaseFormField {
    constructor(columnWidth, bindingValue, validationBindingValue) {
        this.columnWidth = columnWidth
        this.bindingValue = bindingValue;
        this.validationBindingValue = validationBindingValue;
    }

    findByPath(data, path) {
        const keys = path
            .replace(/\[(\d+)\]/g, '.$1') // Convert [index] to .index
            .split('.'); // Split by dot

        // Traverse the object/array based on the path
        return keys.reduce((o, key) => {
            // Check if the current value is null or undefined
            if (o == null) {
                return undefined;
            }

            // Convert key to number if it's an array index
            const indexKey = Number(key);
            const isArrayIndex = !isNaN(indexKey) && indexKey.toString() === key;

            // Access the property or array index
            return isArrayIndex ? (Array.isArray(o) ? o[indexKey] : undefined) : o[key];
        }, data);
    }

    getBindingValue(data) {
        return this.findByPath(data, this.bindingValue);
    }

    getValidationBindingValue(validationErrors) {
        return validationErrors ? this.findByPath(validationErrors, this.validationBindingValue) : null;
    }

    renderField() {
        return <>&nbsp;</>;
    }

}
