import {TgfFormGrid} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfFormGrid";
import {TgfTextBoxFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfTextBoxFormField";
import {TgfComboBoxFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfComboBoxFormField";
import {TgfBaseFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfBaseFormField";

export const getCarrierInformationGrid = () => {
    return new TgfFormGrid({
        isAppCard: true,
        appCardHeader: "Carrier Information",
        fieldList: [
            new TgfTextBoxFormField(
                {
                    columnWidth: 8,
                    id: "carrierInformation.carrierTrackingContactEmail",
                    name: "entity.carrierInformation.carrierTrackingContactEmail",
                    label: "Carrier's Tracking Contact's Email",
                    bindingValue: "entity.carrierInformation.carrierTrackingContactEmail",
                    validationBindingValue: "entity.carrierInformation.carrierTrackingContactEmail",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 4,
                    id: "carrierInformation.driverCellNumber",
                    name: "entity.carrierInformation.driverCellNumber",
                    label: "Driver Cell #",
                    bindingValue: "entity.carrierInformation.driverCellNumber",
                    validationBindingValue: "entity.carrierInformation.driverCellNumber",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 2,
                    id: "carrierInformation.truckNumber",
                    name: "entity.carrierInformation.truckNumber",
                    label: "Truck Number",
                    bindingValue: "entity.carrierInformation.truckNumber",
                    validationBindingValue: "entity.carrierInformation.truckNumber",
                    maxLength: 50,
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 2,
                    id: "carrierInformation.trailerNumber",
                    name: "entity.carrierInformation.trailerNumber",
                    label: "Trailer Number",
                    bindingValue: "entity.carrierInformation.trailerNumber",
                    validationBindingValue: "entity.carrierInformation.trailerNumber",
                    maxLength: 50,
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfComboBoxFormField(
                {
                    columnWidth: 4,
                    id: "carrierInformation.driverType",
                    name: "entity.carrierInformation.driverType",
                    label: "Driver Type",
                    bindingValue: "entity.carrierInformation.driverType",
                    validationBindingValue: "entity.carrierInformation.driverType",
                    dataBindingValue: "additionalData.driverType",
                    disabledFunction: (isEditMode, data, validationErrors) => {
                    }
                }
            ),
            new TgfComboBoxFormField(
                {
                    columnWidth: 4,
                    id: "carrierInformation.teamDrivers",
                    name: "entity.carrierInformation.teamDrivers",
                    label: "Team Drivers",
                    bindingValue: "entity.carrierInformation.teamDrivers",
                    validationBindingValue: "entity.carrierInformation.teamDrivers",
                    dataBindingValue: "additionalData.teamDrivers",
                    disabledFunction: (isEditMode, data, validationErrors) => {
                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 2,
                    id: "carrierInformation.mcNumber",
                    name: "entity.carrierInformation.mcNumber",
                    label: "Carrier MC #",
                    bindingValue: "entity.carrierInformation.mcNumber",
                    validationBindingValue: "entity.carrierInformation.mcNumber",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {
                        return true;
                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 7,
                    id: "carrierInformation.carrierName",
                    name: "entity.carrierInformation.carrierName",
                    label: "Carrier Name",
                    bindingValue: "entity.carrierInformation.carrierName",
                    validationBindingValue: "entity.carrierInformation.carrierName",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {
                        return true;
                    }
                }
            ),
            new TgfBaseFormField(3),
            new TgfTextBoxFormField(
                {
                    columnWidth: 3,
                    id: "carrierInformation.carrierDispatcherName",
                    name: "entity.carrierInformation.carrierDispatcherName",
                    label: "Carrier Dispatcher Name",
                    bindingValue: "entity.carrierInformation.carrierDispatcherName",
                    validationBindingValue: "entity.carrierInformation.carrierDispatcherName",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {
                        return true;
                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 3,
                    id: "carrierInformation.carrierDispatcherPhone",
                    name: "entity.carrierInformation.carrierDispatcherPhone",
                    label: "Carrier Dispatcher Phone #",
                    bindingValue: "entity.carrierInformation.carrierDispatcherPhone",
                    validationBindingValue: "entity.carrierInformation.carrierDispatcherPhone",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {
                        return true;
                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 6,
                    id: "carrierInformation.carrierDispatcherEmail",
                    name: "entity.carrierInformation.carrierDispatcherEmail",
                    label: "Carrier Dispatcher Email",
                    bindingValue: "entity.carrierInformation.carrierDispatcherEmail",
                    validationBindingValue: "entity.carrierInformation.carrierDispatcherEmail",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {
                        return true;
                    }
                }
            ),
        ]
    })
}
