import {TgfBaseFormField} from "./TgfBaseFormField";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import React from "react";
import {TimePicker} from "@progress/kendo-react-dateinputs";
import {Label} from "@progress/kendo-react-labels";
import InfoIconTooltip from "../../../../components/Tooltips/InfoIconTooltip/InfoIconTooltip";

export class TgfTimeInputFormField extends TgfBaseFormField {
    constructor({
                    columnWidth = 1,
                    id = 'date-picker',
                    label = 'DatePicker',
                    format = 'hh:mm a',
                    defaultValue = null,
                    bindingValue = 'entity.value',
                    validationBindingValue = 'entity.value',
                    toolTipText = null,
                    disabledFunction = (isEditMode, data, validationErrors) => {
                        return false;
                    },
                }) {
        super(columnWidth, bindingValue, validationBindingValue);

        this.id = id;
        this.label = label;
        this.format = format;
        this.defaultValue = defaultValue;
        this.toolTipText = toolTipText;
        this.disabledFunction = disabledFunction;
    }

    handleOnChange(e) {
        this.setValue(this.bindingValue, e.target.value);
    };

    renderField(setValue, data, validationErrors, isEditMode) {
        this.setValue = setValue;
        return <>
            <Label editorId={this.id}>{this.label}</Label>
            {this.toolTipText !== null ? <InfoIconTooltip title={`${this.toolTipText}`}></InfoIconTooltip> : <></>}
            <TimePicker
                disabled={this.disabledFunction(isEditMode, data, validationErrors)}
                format={this.format || "hh:mm a"}
                defaultValue={this.value ?? null}
                onChange={this.handleOnChange.bind(this)}
            />
            <FieldError shouldRender={isEditMode}>{this.getValidationBindingValue(validationErrors)}</FieldError>
        </>
    }
}
