import {TgfFormGrid} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfFormGrid";
import {TgfTextBoxFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfTextBoxFormField";
import {TgfBaseFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfBaseFormField";
import {
    TgfDateOnlyPickerFormField
} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfDateOnlyPickerFormField";
import {TgfTimeInputFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfTimeInputFormField";
import {TgfTextAreaFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfTextAreaFormField";
import {TgfToggleGroupFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfToggleGroupFormField";
import {TgfToggleFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfToggleFormField";

export const getDestinationDeliveryLocationGrid = () => {
    return new TgfFormGrid({
        isAppCard: true,
        appCardHeader: "Destination/Pickup Location",
        fieldList: [
            new TgfTextBoxFormField(
                {
                    columnWidth: 6,
                    id: "deliveryLocation.selectedConsignee",
                    name: "entity.deliveryLocation.selectedConsignee",
                    label: "Current Selected Consignee",
                    bindingValue: "entity.deliveryLocation.selectedConsignee",
                    validationBindingValue: "entity.deliveryLocation.selectedConsignee",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {
                        return true;
                    }
                }
            ),
            new TgfBaseFormField(6),
            new TgfTextBoxFormField(
                {
                    columnWidth: 3,
                    id: "deliveryLocation.address1",
                    name: "entity.deliveryLocation.address1",
                    label: "Address 1",
                    bindingValue: "entity.deliveryLocation.address1",
                    validationBindingValue: "entity.deliveryLocation.address1",
                    maxLength: 50,
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 3,
                    id: "deliveryLocation.address2",
                    name: "entity.deliveryLocation.address2",
                    label: "Address 2",
                    bindingValue: "entity.deliveryLocation.address2",
                    validationBindingValue: "entity.deliveryLocation.address2",
                    maxLength: 50,
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 2,
                    id: "deliveryLocation.city",
                    name: "entity.deliveryLocation.city",
                    label: "City",
                    bindingValue: "entity.deliveryLocation.city",
                    validationBindingValue: "entity.deliveryLocation.city",
                    maxLength: 50,
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 2,
                    id: "deliveryLocation.state",
                    name: "entity.deliveryLocation.state",
                    label: "State/Province",
                    bindingValue: "entity.deliveryLocation.state",
                    validationBindingValue: "entity.deliveryLocation.state",
                    maxLength: 50,
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 2,
                    id: "deliveryLocation.postalCode",
                    name: "entity.deliveryLocation.postalCode",
                    label: "Zip/Postal Code",
                    bindingValue: "entity.deliveryLocation.postalCode",
                    validationBindingValue: "entity.deliveryLocation.postalCode",
                    maxLength: 50,
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfDateOnlyPickerFormField(
                {
                    columnWidth: 2,
                    id: "deliveryLocation.enteredDeliveryDate",
                    name: "entity.deliveryLocation.enteredDeliveryDate",
                    label: "Entered Delivery Date",
                    bindingValue: "entity.deliveryLocation.enteredDeliveryDate",
                    validationBindingValue: "entity.deliveryLocation.enteredDeliveryDate",
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {
                        return true;
                    }
                }
            ),
            new TgfDateOnlyPickerFormField(
                {
                    columnWidth: 2,
                    id: "deliveryLocation.expectedArrivalDate",
                    name: "entity.deliveryLocation.expectedArrivalDate",
                    label: "Expected Arrival Date",
                    bindingValue: "entity.deliveryLocation.expectedArrivalDate",
                    validationBindingValue: "entity.deliveryLocation.expectedArrivalDate",
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {
                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 3,
                    id: "deliveryLocation.enteredDeliveryTime",
                    name: "entity.deliveryLocation.enteredDeliveryTime",
                    label: "Entered Delivery Time",
                    bindingValue: "entity.deliveryLocation.enteredDeliveryTime",
                    validationBindingValue: "entity.deliveryLocation.enteredDeliveryTime",
                    toolTipText: 'TBD',
                    maxLength: 50,
                    disabledFunction: (isEditMode, data, validationErrors) => {
                        return true;
                    }
                }
            ),
            new TgfTimeInputFormField(
                {
                    columnWidth: 2,
                    id: "deliveryLocation.latestArrivalTime",
                    label: "Latest Arrival Time",
                    bindingValue: "entity.deliveryLocation.latestArrivalTime",
                    validationBindingValue: "entity.deliveryLocation.latestArrivalTime",
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfTextAreaFormField(
                {
                    columnWidth: 6,
                    id: "deliveryLocation.commentsToDriver",
                    name: "commentsToDriver",
                    label: "Comments to Driver (130 max characters)",
                    bindingValue: "entity.deliveryLocation.commentsToDriver",
                    validationBindingValue: "entity.deliveryLocation.commentsToDriver",
                    maxLength: 130,
                    minRows: 4,
                    disabledFunction: (isEditMode, data, validationErrors) => {
                    }
                }
            ),
            new TgfToggleGroupFormField({
                columnWidth: 6,
                id: "eventsLogged",
                label: "Events at this Location That You Want the Driver to Log",
                toolTipText: 'TBD',
                toggles: [
                    new TgfToggleFormField({
                        columnWidth: 1,
                        id: "deliveryLocation.driverToLogEvents.arrived",
                        name: "entity.deliveryLocation.driverToLogEvents.arrived",
                        label: "Arrived",
                        bindingValue: "entity.deliveryLocation.driverToLogEvents.arrived",
                        validationBindingValue: "entity.deliveryLocation.driverToLogEvents.arrived",
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }),
                    new TgfToggleFormField({
                        columnWidth: 1,
                        id: "deliveryLocation.driverToLogEvents.checkedIn",
                        name: "entity.deliveryLocation.driverToLogEvents.checkedIn",
                        label: "Checked-In",
                        bindingValue: "entity.deliveryLocation.driverToLogEvents.checkedIn",
                        validationBindingValue: "entity.deliveryLocation.driverToLogEvents.checkedIn",
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }),
                    new TgfToggleFormField({
                        columnWidth: 1,
                        id: "deliveryLocation.driverToLogEvents.delivered",
                        name: "entity.deliveryLocation.driverToLogEvents.delivered",
                        label: "Delivered",
                        bindingValue: "entity.deliveryLocation.driverToLogEvents.delivered",
                        validationBindingValue: "entity.deliveryLocation.driverToLogEvents.delivered",
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }),
                    new TgfToggleFormField({
                        columnWidth: 1,
                        id: "deliveryLocation.driverToLogEvents.departed",
                        name: "entity.deliveryLocation.driverToLogEvents.departed",
                        label: "Departed",
                        bindingValue: "entity.deliveryLocation.driverToLogEvents.departed",
                        validationBindingValue: "entity.deliveryLocation.driverToLogEvents.departed",
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }),
                ]
            })
        ]
    });
}
