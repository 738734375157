import {TgfBaseFormField} from "./TgfBaseFormField";
import DateOnlyPicker from "../../../inputs/DateOnlyPicker";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import React from "react";
import InfoIconTooltip from "../../../../components/Tooltips/InfoIconTooltip/InfoIconTooltip";

export class TgfDateOnlyPickerFormField extends TgfBaseFormField {
    constructor({
                    columnWidth = 1,
                    id = 'date-picker',
                    name = 'date-picker-name',
                    label = 'DatePicker',
                    bindingValue = 'entity.value',
                    validationBindingValue = 'entity.value',
                    required = false,
                    toolTipText = null,
                    disabledFunction = (isEditMode, data, validationErrors) => {
                        return false;
                    },
                }) {
        super(columnWidth, bindingValue, validationBindingValue);

        this.id = id;
        this.name = name;
        this.label = label;
        this.required = required;
        this.toolTipText = toolTipText;
        this.disabledFunction = disabledFunction;
    }

    handleDateOnlyPickerChange(e) {
        if (e.target.value !== null) {
            const v = new Date(e.target.value.setUTCHours(12, 0, 0, 0));
            this.setValue(this.bindingValue, v);
        } else {
            this.setValue(this.bindingValue, null);
        }
    };

    renderField(setValue, data, validationErrors, isEditMode) {
        this.setValue = setValue;
        return <>
            {this.toolTipText !== null ? <InfoIconTooltip title={`${this.toolTipText}`}></InfoIconTooltip> : <></>}
            <DateOnlyPicker
                id={this.id}
                name={this.name}
                label={this.label}
                value={this.getBindingValue(data)}
                disabled={this.disabledFunction(isEditMode, data, validationErrors)}
                required={this.required}
                min={new Date()}
                onChange={this.handleDateOnlyPickerChange.bind(this)}
            />
            <FieldError shouldRender={isEditMode}>{this.getValidationBindingValue(validationErrors)}</FieldError>
        </>
    }
}
