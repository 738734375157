import {TgfFormGrid} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfFormGrid";
import {TgfTextBoxFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfTextBoxFormField";
import {TgfBaseFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfBaseFormField";
import {
    TgfDateOnlyPickerFormField
} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfDateOnlyPickerFormField";
import {TgfTimeInputFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfTimeInputFormField";
import {TgfComboBoxFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfComboBoxFormField";
import {TgfTextAreaFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfTextAreaFormField";
import {TgfToggleGroupFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfToggleGroupFormField";
import {TgfToggleFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfToggleFormField";

export const getOriginPickupLocationGrid = () => {
    return new TgfFormGrid({
        isAppCard: true,
        appCardHeader: "Origin/Pickup Location",
        fieldList: [
            new TgfTextBoxFormField(
                {
                    columnWidth: 6,
                    id: "originPickupLocation.currentSelectedShipper",
                    name: "entity.originPickupLocation.currentSelectedShipper",
                    label: "Current Selected Shipper",
                    bindingValue: "entity.originPickupLocation.currentSelectedShipper",
                    validationBindingValue: "entity.originPickupLocation.currentSelectedShipper",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {
                        return true;
                    }
                }
            ),
            new TgfBaseFormField(6),
            new TgfTextBoxFormField(
                {
                    columnWidth: 3,
                    id: "originPickupLocation.address1",
                    name: "entity.originPickupLocation.address1",
                    label: "Address 1",
                    bindingValue: "entity.originPickupLocation.address1",
                    validationBindingValue: "entity.originPickupLocation.address1",
                    maxLength: 50,
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 3,
                    id: "originPickupLocation.address2",
                    name: "entity.originPickupLocation.address2",
                    label: "Address 2",
                    bindingValue: "entity.originPickupLocation.address2",
                    validationBindingValue: "entity.originPickupLocation.address2",
                    maxLength: 50,
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 2,
                    id: "originPickupLocation.city",
                    name: "entity.originPickupLocation.city",
                    label: "City",
                    bindingValue: "entity.originPickupLocation.city",
                    validationBindingValue: "entity.originPickupLocation.city",
                    maxLength: 50,
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 2,
                    id: "originPickupLocation.state",
                    name: "entity.originPickupLocation.state",
                    label: "State/Province",
                    bindingValue: "entity.originPickupLocation.state",
                    validationBindingValue: "entity.originPickupLocation.state",
                    maxLength: 50,
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 2,
                    id: "originPickupLocation.postalCode",
                    name: "entity.originPickupLocation.postalCode",
                    label: "Zip/Postal Code",
                    bindingValue: "entity.originPickupLocation.postalCode",
                    validationBindingValue: "entity.originPickupLocation.postalCode",
                    maxLength: 50,
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfDateOnlyPickerFormField(
                {
                    columnWidth: 2,
                    id: "originPickupLocation.enteredPickupDate",
                    name: "entity.originPickupLocation.enteredPickupDate",
                    label: "Entered Pickup Date",
                    bindingValue: "entity.originPickupLocation.enteredPickupDate",
                    validationBindingValue: "entity.originPickupLocation.enteredPickupDate",
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {
                        return true;
                    }
                }
            ),
            new TgfDateOnlyPickerFormField(
                {
                    columnWidth: 2,
                    id: "originPickupLocation.expectedArrivalDate",
                    name: "entity.originPickupLocation.expectedArrivalDate",
                    label: "Expected Arrival Date",
                    bindingValue: "entity.originPickupLocation.expectedArrivalDate",
                    validationBindingValue: "entity.originPickupLocation.expectedArrivalDate",
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {
                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 2,
                    id: "originPickupLocation.enteredPickupTime",
                    name: "entity.originPickupLocation.enteredPickupTime",
                    label: "Entered Pickup Time",
                    bindingValue: "entity.originPickupLocation.enteredPickupTime",
                    validationBindingValue: "entity.originPickupLocation.enteredPickupTime",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {
                        return true;
                    }
                }
            ),
            new TgfTimeInputFormField(
                {
                    columnWidth: 2,
                    id: "originPickupLocation.latestArrivalTime",
                    label: "Latest Arrival Time",
                    bindingValue: "entity.originPickupLocation.latestArrivalTime",
                    validationBindingValue: "entity.originPickupLocation.latestArrivalTime",
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfComboBoxFormField(
                {
                    columnWidth: 4,
                    id: "originPickupLocation.hoursBeforeArrivalTimeToStartTracking",
                    name: "entity.originPickupLocation.hoursBeforeArrivalTimeToStartTracking",
                    label: "How many hours before the Latest Arrival Time should the tracking start",
                    bindingValue: "entity.originPickupLocation.hoursBeforeArrivalTimeToStartTracking",
                    validationBindingValue: "entity.originPickupLocation.hoursBeforeArrivalTimeToStartTracking",
                    dataBindingValue: "additionalData.hoursBeforeArrivalTimeToStartTracking",
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {
                    }
                }
            ),
            new TgfTextAreaFormField(
                {
                    columnWidth: 6,
                    id: "originPickupLocation.commentsToDriver",
                    name: "commentsToDriver",
                    label: "Comments to Driver (130 max characters)",
                    bindingValue: "entity.originPickupLocation.commentsToDriver",
                    validationBindingValue: "entity.originPickupLocation.commentsToDriver",
                    maxLength: 130,
                    minRows: 4,
                    disabledFunction: (isEditMode, data, validationErrors) => {
                    }
                }
            ),
            new TgfToggleGroupFormField({
                columnWidth: 6,
                id: "commentsToDriver",
                label: "Events at this Location That You Want the Driver to Log",
                toolTipText: 'TBD',
                toggles: [
                    new TgfToggleFormField({
                        columnWidth: 1,
                        id: "originPickupLocation.driverToLogEvents.arrived",
                        name: "entity.originPickupLocation.driverToLogEvents.arrived",
                        label: "Arrived",
                        bindingValue: "entity.originPickupLocation.driverToLogEvents.arrived",
                        validationBindingValue: "entity.originPickupLocation.driverToLogEvents.arrived",
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }),
                    new TgfToggleFormField({
                        columnWidth: 1,
                        id: "originPickupLocation.driverToLogEvents.checkedIn",
                        name: "entity.originPickupLocation.driverToLogEvents.checkedIn",
                        label: "Checked-In",
                        bindingValue: "entity.originPickupLocation.driverToLogEvents.checkedIn",
                        validationBindingValue: "entity.originPickupLocation.driverToLogEvents.checkedIn",
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }),
                    new TgfToggleFormField({
                        columnWidth: 1,
                        id: "originPickupLocation.driverToLogEvents.pickedUp",
                        name: "entity.originPickupLocation.driverToLogEvents.pickedUp",
                        label: "Delivered",
                        bindingValue: "entity.originPickupLocation.driverToLogEvents.pickedUp",
                        validationBindingValue: "entity.originPickupLocation.driverToLogEvents.pickedUp",
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }),
                    new TgfToggleFormField({
                        columnWidth: 1,
                        id: "originPickupLocation.driverToLogEvents.departed",
                        name: "entity.originPickupLocation.driverToLogEvents.departed",
                        label: "Departed",
                        bindingValue: "entity.originPickupLocation.driverToLogEvents.departed",
                        validationBindingValue: "entity.originPickupLocation.driverToLogEvents.departed",
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }),
                ]
            })
        ]
    });
}
