import {makeStyles} from "@material-ui/core/styles";
import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import {Typography} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 200,
        height: 230,
        overflow: 'auto',
    },
    button: {
        marginTop: 20,
        width: 200,
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const TgfMultiList = forwardRef(function TgfDataGrid(props, ref) {

    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(props.left);
    const [right, setRight] = React.useState(props.right);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    useEffect(() => {
        props.onChange({left: left, right: right});
    }, [left, right]);

    useImperativeHandle(ref, () => ({
        reset(left, right) {
            setLeft(left);
            setRight(right);
        }
    }));

    const customList = (items, label) => (
        <>
            <Typography variant="h6">{label}</Typography>
            <Paper className={classes.paper}  style={{ width: '100%' }}>
                <List dense component="div" role="list">
                    {items.map((item) => {
                        return (
                            <ListItem key={item} role="listitem" button onClick={handleToggle(item)}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(item) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={props.idFunction(item)}
                                    primary={props.displayFunction(item)}
                                />
                            </ListItem>
                        );
                    })}
                    <ListItem/>
                </List>
            </Paper>
        </>
    );

    return (
        <>
            <Grid item xs={2}>{customList(left, props.leftLabel)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                    >
                        Remove Selected &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt; Add Selected
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={2}>{customList(right, props.rightLabel)}</Grid>
        </>
    );
});

export default TgfMultiList;
