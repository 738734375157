import {TgfFormGrid} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfFormGrid";
import {TgfTextBoxFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfTextBoxFormField";
import {TgfToggleFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfToggleFormField";
import {TgfBaseFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfBaseFormField";
import {TgfComboBoxFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfComboBoxFormField";

export const getAdditionalCommunicationGrid = () => {
    return new TgfFormGrid({
        isAppCard: true,
        appCardHeader: "Additional Communication",
        fieldList: [
            new TgfTextBoxFormField(
                {
                    columnWidth: 6,
                    id: "additionalCommunication.additionalEmailsForTrackingUpdates",
                    name: "entity.additionalCommunication.additionalEmailsForTrackingUpdates",
                    label: "Additional Email Addresses for Tracking (separate addresses by semi-colon)",
                    bindingValue: "entity.additionalCommunication.additionalEmailsForTrackingUpdates",
                    validationBindingValue: "entity.additionalCommunication.additionalEmailsForTrackingUpdates",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfToggleFormField(
                {
                    columnWidth: 3,
                    id: "additionalCommunication.sendEmailsWhenPickedUpAndDelivered",
                    name: "entity.additionalCommunication.sendEmailsWhenPickedUpAndDelivered",
                    label: "Send emails when the shipment is picked up and delivered",
                    bindingValue: "entity.additionalCommunication.sendEmailsWhenPickedUpAndDelivered",
                    validationBindingValue: "entity.additionalCommunication.sendEmailsWhenPickedUpAndDelivered",
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfBaseFormField(3),
            new TgfTextBoxFormField(
                {
                    columnWidth: 6,
                    id: "additionalCommunication.referenceNumberForEmailUpdates",
                    name: "entity.additionalCommunication.referenceNumberForEmailUpdates",
                    label: "Reference Number(s) for Emailed Updates",
                    bindingValue: "entity.additionalCommunication.referenceNumberForEmailUpdates",
                    validationBindingValue: "entity.additionalCommunication.referenceNumberForEmailUpdates",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfToggleFormField(
                {
                    columnWidth: 3,
                    id: "additionalCommunication.locationUpdates.sendUpdates",
                    name: "entity.additionalCommunication.locationUpdates.sendUpdates",
                    label: "Send location updates",
                    bindingValue: "entity.additionalCommunication.locationUpdates.sendUpdates",
                    validationBindingValue: "entity.additionalCommunication.locationUpdates.sendUpdates",
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfComboBoxFormField(
                {
                    columnWidth: 3,
                    id: "additionalCommunication.locationUpdates.updateSchedule",
                    name: "entity.additionalCommunication.locationUpdates.updateSchedule",
                    label: "",
                    bindingValue: "entity.additionalCommunication.locationUpdates.updateSchedule",
                    validationBindingValue: "entity.additionalCommunication.locationUpdates.updateSchedule",
                    dataBindingValue: "additionalData.updateSchedule",
                    disabledFunction: (isEditMode, data, validationErrors) => {
                    }
                }
            ),
        ]
    });
}
