import {TgfFormGrid} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfFormGrid";
import {TgfTextBoxFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfTextBoxFormField";
import {TgfBaseFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfBaseFormField";
import {TgfButtonFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfButtonFormField";

export const getSubmitGrid = (ref) => {
    return new TgfFormGrid({
        fieldList: [
            new TgfBaseFormField(12),
            new TgfBaseFormField(5),
            new TgfButtonFormField({
                columnWidth: 2,
                variant: "contained",
                color: "",
                type: "submit",
                disabledFunction: (isEditMode, data, validationErrors) => {
                    return validationErrors !== null;
                },
                contentFunction: (isEditMode, data, validationErrors) => {
                    return "Submit Autotrack";
                },
                onClickHandler: (isEditMode, data, validationErrors) => {
                    ref.current?.submitForm();
                },
            }),
        ]
    });
}
