import {TgfFormGrid} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfFormGrid";
import {TgfTextBoxFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfTextBoxFormField";

export const getHeaderGrid = () => {
    return new TgfFormGrid({
        fieldList: [
            new TgfTextBoxFormField(
                {
                    columnWidth: 6,
                    id: "loadNumber",
                    name: "entity.loadNumber",
                    label: "Load # / Load ID (50 max characters)",
                    bindingValue: "entity.loadNumber",
                    validationBindingValue: "entity.loadNumber",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
            new TgfTextBoxFormField(
                {
                    columnWidth: 6,
                    id: "tgfContactEmail",
                    name: "entity.tgfContactEmail",
                    label: "Tallgrass Freight Contact Email",
                    bindingValue: "entity.tgfContactEmail",
                    validationBindingValue: "entity.tgfContactEmail",
                    maxLength: 50,
                    toolTipText: 'TBD',
                    disabledFunction: (isEditMode, data, validationErrors) => {

                    }
                }
            ),
        ]
    });
}
