import * as yup from "yup";

export const getAutoTrackEntryValidation = () => {
    return yup.object({
        entity: yup.object().shape({
            loadNumber: yup.string().required(),
            tgfContactEmail: yup.string().required(),
            carrierInformation: yup.object().shape({
                carrierTrackingContactEmail: yup.string().required(),
                mcNumber: yup.string().required(),
                carrierName: yup.string().required(),
                carrierDispatcherName: yup.string().required(),
                carrierDispatcherPhone: yup.string().required(),
                carrierDispatcherEmail: yup.string().required(),
            }),
            originPickupLocation: yup.object().shape({
                address1: yup.string().required(),
                city: yup.string().required(),
                state: yup.string().required(),
                postalCode: yup.string().required(),
                expectedArrivalDate: yup.string().required(),
                latestArrivalTime: yup.string().required(),
                hoursBeforeArrivalTimeToStartTracking: yup.string().required(),
                driverToLogEvents: yup.object().shape({
                    arrived: yup.string()
                })
            }),
            multiStop: yup.array()
                .of(
                    yup.object().shape({
                        address1: yup.string().required(),
                        city: yup.string().required(),
                        state: yup.string().required(),
                        postalCode: yup.string().required(),
                        expectedArrivalDate: yup.string().required(),
                        latestArrivalTime: yup.string().required(),
                    })
                ),
            deliveryLocation: yup.object().shape({
                address1: yup.string().required(),
                city: yup.string().required(),
                state: yup.string().required(),
                postalCode: yup.string().required(),
                expectedArrivalDate: yup.string().required(),
                latestArrivalTime: yup.string().required(),
                driverToLogEvents: yup.object().shape({
                    arrived: yup.string()
                })
            }),
        }),
    });
}
