import {Grid} from "@material-ui/core";
import AppMuiCard from "../../../../components/cards/AppCard";

export class TgfFormGrid {
    constructor({
                    style = {},
                    spacing = 2,
                    fieldList = [],
                    isAppCard = false,
                    appCardHeader = '',
                    appCardHeight = '100px',
                    isVisibleFunction = (data, isEditMode) => {
                        return true
                    }
                }) {
        this.fieldList = fieldList;
        this.style = style;
        this.spacing = spacing;
        this.isAppCard = isAppCard;
        this.appCardHeader = appCardHeader;
        this.appCardHeight = appCardHeight;
        this.isVisibleFunction = isVisibleFunction;
    }

    renderGrid(setValue, data, validationErrors, isEditMode) {
        if (this.isVisibleFunction(data, isEditMode)) {
            if (this.isAppCard) {
                return <AppMuiCard
                    title={`${this.appCardHeader}`}
                    height={this.appCardHeight}
                >
                    <Grid container spacing={this.spacing} style={this.style}>

                        {this.renderFieldList(setValue, data, validationErrors, isEditMode)}
                    </Grid>
                </AppMuiCard>
            } else {
                return <Grid container spacing={this.spacing} style={this.style}>
                    {
                        this.renderFieldList(setValue, data, validationErrors, isEditMode)
                    }
                </Grid>
                    ;
            }
        } else {
            return <></>;
        }
    }

    renderFieldList(setValue, data, validationErrors, isEditMode) {
        return <>
            {this.fieldList.map((field, i) => this.renderField(field, setValue, data, validationErrors, isEditMode))}
        </>
    }

    renderField(field, setValue, data, validationErrors, isEditMode) {
        return <Grid item xs={field.columnWidth}>
            {field.renderField(setValue, data, validationErrors, isEditMode)}
        </Grid>

    }

}
