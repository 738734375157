import {TgfBaseFormField} from "./TgfBaseFormField";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import React from "react";
import {Label} from "@progress/kendo-react-labels";
import TgfTextBox from "../../inputs/TgfTextBox";
import InfoIconTooltip from "../../../../components/Tooltips/InfoIconTooltip/InfoIconTooltip";

export class TgfTextBoxFormField extends TgfBaseFormField {
    constructor({
                    columnWidth = 1,
                    id = 'text-box',
                    name = 'text-box-name',
                    label = 'TextBox',
                    bindingValue = 'entity.value',
                    validationBindingValue = 'entity.value',
                    maxLength = 20,
                    required = false,
                    toolTipText = null,
                    disabledFunction = (isEditMode, data, validationErrors) => {
                        return false;
                    },
                }) {
        super(columnWidth, bindingValue, validationBindingValue);

        this.id = id;
        this.name = name;
        this.label = label;
        this.maxLength = maxLength;
        this.required = required;
        this.toolTipText = toolTipText;
        this.disabledFunction = disabledFunction;
    }

    handleTextChange(e) {
        this.setValue(this.bindingValue, e.target.value);
    };

    renderField(setValue, data, validationErrors, isEditMode) {
        this.setValue = setValue;
        return <>
            <Label editorId={this.id}>{this.label}</Label>
            {this.toolTipText !== null ? <InfoIconTooltip title={`${this.toolTipText}`}></InfoIconTooltip> : <></>}
            <TgfTextBox
                id={this.id}
                name={this.name}
                value={this.getBindingValue(data)}
                maxLength={this.maxLength}
                onChange={this.handleTextChange.bind(this)}
                disabled={this.disabledFunction(isEditMode, data, validationErrors)}
                required={this.required}
            />
            <FieldError shouldRender={isEditMode}>{this.getValidationBindingValue(validationErrors)}</FieldError>
        </>
    }
}
