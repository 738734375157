import {TgfBaseFormField} from "./TgfBaseFormField";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import React from "react";
import TgfComboBox from "../../inputs/TgfComboBox";
import InfoIconTooltip from "../../../../components/Tooltips/InfoIconTooltip/InfoIconTooltip";

export class TgfComboBoxFormField extends TgfBaseFormField {
    constructor({
                    columnWidth = 1,
                    id = 'combo-box',
                    name = 'combo-box-name',
                    label = 'ComboBox',
                    bindingValue = 'entity.value',
                    validationBindingValue = 'entity.value',
                    dataBindingValue = 'entity.additionalData.value',
                    required = false,
                    toolTipText = null,
                    disabledFunction = (isEditMode, data, validationErrors) => {
                        return false;
                    },
                }) {
        super(columnWidth, bindingValue, validationBindingValue);

        this.id = id;
        this.name = name;
        this.label = label;
        this.required = required;
        this.toolTipText = toolTipText;
        this.dataBindingValue = dataBindingValue;

        this.disabledFunction = disabledFunction;
    }

    handleStringIdChange (e) {
        this.setValue(this.bindingValue, e.target.value?.id);
    };


    getDataBindingValue(data) {
        return this.findByPath(data, this.dataBindingValue);
    }

    renderField(setValue, data, validationErrors, isEditMode) {
        this.setValue = setValue;
        return <>
            {this.toolTipText !== null ? <InfoIconTooltip title={`${this.toolTipText}`}></InfoIconTooltip> : <></>}
            <TgfComboBox
                label={this.label}
                id={this.id}
                name={this.name }
                data={this.getDataBindingValue(data)}
                value={this.getBindingValue(data)}
                onChange={this.handleStringIdChange.bind(this)}
                disabled={this.disabledFunction(isEditMode, data, validationErrors)}
                required={this.required}
            />
            <FieldError shouldRender={isEditMode}>{this.getValidationBindingValue(validationErrors)}</FieldError>
        </>
    }
}
