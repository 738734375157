import {TgfBaseFormField} from "./TgfBaseFormField";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import React from "react";
import TgfToggle from "../../inputs/TgfToggle";

export class TgfToggleFormField extends TgfBaseFormField {
    constructor({
                    columnWidth = 1,
                    id = 'toggle',
                    name = 'toggle-name',
                    label = 'toggle',
                    bindingValue = 'entity.value',
                    validationBindingValue = 'entity.value',
                }) {
        super(columnWidth, bindingValue, validationBindingValue);

        this.id = id;
        this.name = name;
        this.label = label;
    }

    handleOnChange(e) {
        this.setValue(this.bindingValue, e.target.value);
    };

    renderField(setValue, data, validationErrors, isEditMode) {
        this.setValue = setValue;
        return <>
            <TgfToggle
                label={this.label}
                id={this.id}
                name={this.name}
                checked={this.getBindingValue(data)}
                onChange={this.handleOnChange.bind(this)}
            />
            <FieldError shouldRender={isEditMode}>{this.getValidationBindingValue(validationErrors)}</FieldError>
        </>
    }
}
