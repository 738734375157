import React from 'react';
import Core from '@atomos/core';
import {Grid} from '@material-ui/core';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import ListingFilterBar from './ListingFilterBar';
import CrmAssociateComboBox from "../../../crm/components/CrmAssociateComboBox";
import RoleNames from "../../../hubs/persona/RoleNames";
import CrmShipmentBolNumberSearchComboBox from "../../../crm/components/CrmShipmentBolNumberSearchComboBox";
import "./ListingPage.scss";
import {useDispatch, useSelector} from "react-redux";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import ShipmentsListingPage from "./includes/ShipmentsListingPage";
import PinnedShipments from "./includes/PinnedShipments";
import AppSwitch from "../../../core/components/inputs/AppSwitch/AppMuiSwitch";
import InfoIconTooltip from "../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import OdataUtils from "../../../core/utils/OdataUtils";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const ListingPage = (props) => {

    const {
        match,
        history,
        load,
    } = props;

    const {
        trackedSet
    } = match.params;

    const personalDispatch = useDispatch();
    usePageTitle(`TGF: Load Board`);


    const ref = React.useRef();
    const refPinned = React.useRef();

    const isAdmin = useIsAdministrator();
    const associateRoles = useSelector(state => state.persona.roles);
    const queenRoleId = Core.Utils.get(Core.Utils.find(associateRoles, r => r.name === RoleNames.Queen), 'id', null);

    const activeAgentId = useSelector((state) => {
        return state.persona.associate.id
    });

    const [filterAssociate, setFilterAssociate] = React.useState(null);
    const [trackedCounts, setTrackedCounts] = React.useState(null);
    const [isRenderable, setIsRenderable] = React.useState(false);
    const [isPinnedShipments, setIsPinnedShipments] = React.useState(false);
    const [showPinnedShipmentsOverride, setShowPinnedShipmentsOverride] = React.useState(true);
    const [noteAdded, setNoteAdded] = React.useState(0);

    React.useEffect(() => {
        if (!trackedSet) {
            history.replace('/load-board/working');
        } else {
            loadPageDependencies(trackedSet, filterAssociate);
        }
    }, [trackedSet, filterAssociate, history, load]);

    React.useEffect(() => {
        ref.current?.refresh();
    }, [trackedSet, filterAssociate, noteAdded]);

    const loadPageDependencies = async (trackedSet, associateId) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadPageDependencies'));

            const countTrackedParams = {};

            if (associateId) {
                countTrackedParams.associateId = associateId;
            }

            const [
                trackedCounts,
            ] = await Promise.all([
                window.shell.gateway.getTrackedShipmentCounts(countTrackedParams),
            ])

            setTrackedCounts(trackedCounts);

            setIsRenderable(true);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadPageDependencies'));
        }
    }

    const applyAdditionalFilters = (odata) => {
        // setting a filter here
        odata.filter = `${filterAssociate}${trackedSet}`;
        return odata;
    }

    const loadGridData = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadShipmentGridData'));
            const query = OdataUtils.odataToQueryStringObjectWithoutFilters(odata);
            query.statusId = trackedSet;
            if(filterAssociate) query.filterAssociate = filterAssociate;

            return await window.shell.gateway.getTrackedShipments(query);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadShipmentGridData'));
        }

    };

    const loadPinnedData = async (odata) => {
        const dataPage = await window.shell.gateway.getShipmentPins(odata)

        setIsPinnedShipments(dataPage.items.length > 0);

        return dataPage;
    };

    const handlePinStatusChange = async (dataItem) => {
        personalDispatch(window.shell.actions.sys.processStart('handleCoveredChange'));

        try {
            if (dataItem.shipmentPinned === 0) {
                await window.shell.gateway.setShipmentPin(dataItem.bolNumber, activeAgentId);
            } else {
                await window.shell.gateway.unsetShipmentPin(dataItem.bolNumber, activeAgentId);
            }
        } catch {

        } finally {
            ref.current?.refresh();
            refPinned.current?.refresh();
            personalDispatch(window.shell.actions.sys.processComplete('handleCoveredChange'));
        }
    }

    const handleNoteAdded = () => setNoteAdded(noteAdded + 1);

    const handleShowPinnedShipmentsOverrideChange = async () => {
        setShowPinnedShipmentsOverride(!showPinnedShipmentsOverride);
    }

    const handleSelectedBolNumber = (selectedBolNumber) => {
        if (selectedBolNumber)
            history.push(`/shipment/${selectedBolNumber}/details`);
    };

    const onSelectAssociate = (associate) => {
        associate ?
          setFilterAssociate(associate.id) :
          setFilterAssociate(null);
    };

    const title = (
      <Grid container>
          <Grid item xs={1}>
              <div style={{position: "relative"}}>
                  <span className={"load-board-title"}>Load Board</span>
              </div>
          </Grid>
          <Grid item xs={9} style={{
              visibility: isPinnedShipments ? "" : "hidden",
          }}>
              <AppSwitch

                checked={showPinnedShipmentsOverride}
                onChange={() => {
                    handleShowPinnedShipmentsOverrideChange()
                }}
                label={showPinnedShipmentsOverride ? 'Show Pinned Shipments' : 'Show Pinned Shipments'}
              />
          </Grid>
          <Grid item xs={2}>
              <CrmShipmentBolNumberSearchComboBox
                placeholder={'Shipment number ...'}
                onChange={handleSelectedBolNumber}
                filterMap={Core.Utils.identity}
                popupIcon={' '}
                style={{minWidth: 100}}
              />
          </Grid>
      </Grid>
    );

    return (
      <FullWidthLayout title={title} className={"shipments-styles"}>
          <React.Fragment>
              {isRenderable && <React.Fragment>
                  <Grid container spacing={1} style={{
                      visibility: isPinnedShipments && showPinnedShipmentsOverride ? "" : "hidden",
                      maxHeight: isPinnedShipments && showPinnedShipmentsOverride ? "100%" : "0"
                  }}>
                      <Grid item xs={1}>
                          <h2>
                              Pinned Shipments
                          </h2>
                      </Grid>
                      <Grid item xs={11}>
                          <div style={{position: "relative"}}>
                              <div style={{position: "absolute", top: "15px"}}>
                                  <InfoIconTooltip
                                    title={`Shipments below have the "Pin Shipment" option currently toggled on within the shipment. To remove a shipment displayed below, toggle off the "Pin Shipment" option within the shipment. If you want to hide this Pinned Shipments section, toggle off the "Show Pinned Shipments" toggle at the top of your Load Board page.`}
                                  />
                              </div>
                          </div>
                      </Grid>
                      <Grid item xs={12}>
                          <PinnedShipments
                            ref={refPinned}
                            onLoadData={loadPinnedData}
                            onPinChange={handlePinStatusChange}
                          />
                      </Grid>
                      &nbsp;
                  </Grid>

                  <Grid container spacing={1}>
                      <Grid item xs={12} md={10}>
                          <Grid container spacing={2}>
                              <Grid item>
                                  <ListingFilterBar
                                    trackedSet={trackedSet}
                                    counts={trackedCounts}
                                    isAdmin={isAdmin}
                                  />
                              </Grid>
                              <Grid item>
                                  {
                                    isAdmin &&
                                    <CrmAssociateComboBox
                                      placeholder={'Search agents...'}
                                      roleId={queenRoleId}
                                      onSelectAssociate={onSelectAssociate}
                                      style={{minWidth: '250px'}}
                                    />
                                  }
                              </Grid>
                          </Grid>
                      </Grid>
                      <Grid item xs={12}>
                          <ShipmentsListingPage
                            ref={ref}
                            onLoadGridData={loadGridData}
                            applyAdditionalFilters={applyAdditionalFilters}
                            onPinChange={handlePinStatusChange}
                            onNoteAdded={handleNoteAdded}
                          />
                      </Grid>
                  </Grid>
              </React.Fragment>
              }
          </React.Fragment>
      </FullWidthLayout>
    );
};

export default ListingPage
