import {TgfFormGrid} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfFormGrid";
import {TgfTextBoxFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfTextBoxFormField";
import {TgfBaseFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfBaseFormField";
import {TgfButtonFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfButtonFormField";
import {
    TgfDateOnlyPickerFormField
} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfDateOnlyPickerFormField";
import {TgfTimeInputFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfTimeInputFormField";
import {TgfTextAreaFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfTextAreaFormField";
import {TgfToggleGroupFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfToggleGroupFormField";
import {TgfToggleFormField} from "../../../../../core/kendo/form/tgfFormComponent/fields/TgfToggleFormField";

export const getMultiStopButtonGrid = (setGridList, ref) => {

    const createMultiStopSection = (i) => {
        return new TgfFormGrid({
            isAppCard: true,
            appCardHeader: `Multi-Stop ${i + 1}`,
            fieldList: [
                new TgfTextBoxFormField(
                    {
                        columnWidth: 6,
                        id: `multiStop[${i}].currentSelectedShipper`,
                        name: `entity.multiStop[${i}].currentSelectedShipper`,
                        label: `Current Selected Shipper`,
                        bindingValue: `entity.multiStop[${i}].currentSelectedShipper`,
                        validationBindingValue: `entity.multiStop[${i}].currentSelectedShipper`,
                        maxLength: 50,
                        toolTipText: 'TBD',
                        disabledFunction: (isEditMode, data, validationErrors) => {
                            return true;
                        }
                    }
                ),
                new TgfBaseFormField(6),
                new TgfTextBoxFormField(
                    {
                        columnWidth: 3,
                        id: `multiStop[${i}].address1`,
                        name: `entity.multiStop[${i}].address1`,
                        label: `Address 1`,
                        bindingValue: `entity.multiStop[${i}].address1`,
                        validationBindingValue: `entity.multiStop[${i}].address1`,
                        maxLength: 50,
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }
                ),
                new TgfTextBoxFormField(
                    {
                        columnWidth: 3,
                        id: `multiStop[${i}].address2`,
                        name: `entity.multiStop[${i}].address2`,
                        label: `Address 2`,
                        bindingValue: `entity.multiStop[${i}].address2`,
                        validationBindingValue: `entity.multiStop[${i}].address2`,
                        maxLength: 50,
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }
                ),
                new TgfTextBoxFormField(
                    {
                        columnWidth: 2,
                        id: `multiStop[${i}].city`,
                        name: `entity.multiStop[${i}].city`,
                        label: `City`,
                        bindingValue: `entity.multiStop[${i}].city`,
                        validationBindingValue: `entity.multiStop[${i}].city`,
                        maxLength: 50,
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }
                ),
                new TgfTextBoxFormField(
                    {
                        columnWidth: 2,
                        id: `multiStop[${i}].state`,
                        name: `entity.multiStop[${i}].state`,
                        label: `State/Province`,
                        bindingValue: `entity.multiStop[${i}].state`,
                        validationBindingValue: `entity.multiStop[${i}].state`,
                        maxLength: 50,
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }
                ),
                new TgfTextBoxFormField(
                    {
                        columnWidth: 2,
                        id: `multiStop[${i}].postalCode`,
                        name: `entity.multiStop[${i}].postalCode`,
                        label: `Zip/Postal Code`,
                        bindingValue: `entity.multiStop[${i}].postalCode`,
                        validationBindingValue: `entity.multiStop[${i}].postalCode`,
                        maxLength: 50,
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }
                ),
                new TgfDateOnlyPickerFormField(
                    {
                        columnWidth: 2,
                        id: `multiStop[${i}].expectedArrivalDate`,
                        name: `entity.multiStop[${i}].expectedArrivalDate`,
                        label: `Expected Arrival Date`,
                        bindingValue: `entity.multiStop[${i}].expectedArrivalDate`,
                        validationBindingValue: `entity.multiStop[${i}].expectedArrivalDate`,
                        toolTipText: 'TBD',
                        disabledFunction: (isEditMode, data, validationErrors) => {
                        }
                    }
                ),
                new TgfTimeInputFormField(
                    {
                        columnWidth: 2,
                        id: `multiStop[${i}].latestArrivalTime`,
                        label: `Latest Arrival Time`,
                        bindingValue: `entity.multiStop[${i}].latestArrivalTime`,
                        validationBindingValue: `entity.multiStop[${i}].latestArrivalTime`,
                        toolTipText: 'TBD',
                        disabledFunction: (isEditMode, data, validationErrors) => {

                        }
                    }
                ),

                new TgfTextAreaFormField(
                    {
                        columnWidth: 6,
                        id: `multiStop[${i}].commentsToDriver`,
                        name: `commentsToDriver`,
                        label: `Comments to Driver (130 max characters)`,
                        bindingValue: `entity.multiStop[${i}].commentsToDriver`,
                        validationBindingValue: `entity.multiStop[${i}].commentsToDriver`,
                        maxLength: 130,
                        minRows: 4,
                        disabledFunction: (isEditMode, data, validationErrors) => {
                        }
                    }
                ),
                new TgfToggleGroupFormField({
                    columnWidth: 6,
                    id: `commentsToDriver`,
                    label: `Events at this Location That You Want the Driver to Log`,
                    toolTipText: 'TBD',
                    toggles: [
                        new TgfToggleFormField({
                            columnWidth: 1,
                            id: `multiStop[${i}].driverToLogEvents.arrived`,
                            name: `entity.multiStop[${i}].driverToLogEvents.arrived`,
                            label: `Arrived`,
                            bindingValue: `entity.multiStop[${i}].driverToLogEvents.arrived`,
                            validationBindingValue: `entity.multiStop[${i}].driverToLogEvents.arrived`,
                            disabledFunction: (isEditMode, data, validationErrors) => {

                            }
                        }),
                        new TgfToggleFormField({
                            columnWidth: 1,
                            id: `multiStop[${i}].driverToLogEvents.checkedIn`,
                            name: `entity.multiStop[${i}].driverToLogEvents.checkedIn`,
                            label: `Checked-In`,
                            bindingValue: `entity.multiStop[${i}].driverToLogEvents.checkedIn`,
                            validationBindingValue: `entity.multiStop[${i}].driverToLogEvents.checkedIn`,
                            disabledFunction: (isEditMode, data, validationErrors) => {

                            }
                        }),
                        new TgfToggleFormField({
                            columnWidth: 1,
                            id: `multiStop[${i}].driverToLogEvents.pickedUp`,
                            name: `entity.multiStop[${i}].driverToLogEvents.pickedUp`,
                            label: `Delivered`,
                            bindingValue: `entity.multiStop[${i}].driverToLogEvents.pickedUp`,
                            validationBindingValue: `entity.multiStop[${i}].driverToLogEvents.pickedUp`,
                            disabledFunction: (isEditMode, data, validationErrors) => {

                            }
                        }),
                        new TgfToggleFormField({
                            columnWidth: 1,
                            id: `multiStop[${i}].driverToLogEvents.departed`,
                            name: `entity.multiStop[${i}].driverToLogEvents.departed`,
                            label: `Departed`,
                            bindingValue: `entity.multiStop[${i}].driverToLogEvents.departed`,
                            validationBindingValue: `entity.multiStop[${i}].driverToLogEvents.departed`,
                            disabledFunction: (isEditMode, data, validationErrors) => {

                            }
                        }),
                    ]
                })
            ]
        });
    }

    function createNewMultiStop(data) {
        data.entity.multiStop.push({
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            expectedArrivalDate: null,
            latestArrivalTime: null,
            commentsToDriver: null,
            driverToLogEvents: {
                arrived: false,
                checkedIn: false,
                pickedUp: false,
                departed: false,
            }
        });

        ref.current?.validateForm();

        const updateGridList = () => {
            setGridList(prevGridList => {
                // Create a new array of items with 'Multi' in the header
                const newMultiStops = prevGridList.filter(obj => obj.appCardHeader.startsWith('Multi'));

                // Add a new multi-stop section to the end of the newMultiStops array
                newMultiStops.push(createMultiStopSection(data.entity.multiStop.length - 1));

                // Create a new array with items that do not have 'Multi' in the header
                const newGridList = prevGridList.filter(obj => !obj.appCardHeader.startsWith('Multi'));

                // Insert the new multi-stop items at index 3 in the newGridList array
                newGridList.splice(3, 0, ...newMultiStops);

                // Return the new grid list to update the state
                return newGridList;
            });
        };

        // Call the function to perform the update
        updateGridList();

    }

    return new TgfFormGrid({
        fieldList: [
            new TgfBaseFormField(12),
            new TgfBaseFormField(5),
            new TgfButtonFormField({
                columnWidth: 2,
                variant: "contained",
                color: "",
                type: "",
                disabledFunction: (isEditMode, data, validationErrors) => {

                },
                contentFunction: (isEditMode, data, validationErrors) => {
                    return "add another stop";
                },
                onClickHandler: (isEditMode, data, validationErrors) => {
                    createNewMultiStop(data);
                },
            }),
            new TgfBaseFormField(5),
            new TgfBaseFormField(12),
        ]
    });
}
