import React from 'react';
import Core from '@atomos/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import { Tooltip } from "@material-ui/core";
import { Email } from '@material-ui/icons';
import {useSelector} from "react-redux";
import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";
import useIsAdministrator from "../../../../hubs/personaV2/selectors/useIsAdministrator";

const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        link: {
            textDecoration: 'none',
            color: theme.palette.tgfLink.main,
            fontWeight: 'bold',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        carrierRepLabel: {
            marginRight: 8,
        },
        emailIcon: {
            fontSize: '1.25em',
            color: theme.palette.primary.main
        }
    }
));

const CarrierHistoryListing = (props) => {

    const {
        limitDataVisibility,
        agencyId,
        count,
        data,
        orderBy,
        order,
        rowsPerPage,
        page,
        onChangePage,
        onChangeRowsPerPage,
        onSort,
    } = props
    const classes = useStyles();
    const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);
    const BolNumberLinkCell = (agencyId) => (props) => {
        const shipment = props.rowData;

        if (Core.Utils.isVoid(agencyId) || shipment.associateId === agencyId) {
            return (
                <Link to={`/shipment/${shipment.shipmentBolNumber}/details`} target={ openLinksInNewTab ? "_blank" : "" } className={classes.link}>{shipment.shipmentBolNumber}</Link>
            );
        }
        else {
            return shipment.shipmentBolNumber;
        }
    };

    const CarrierNameLinkCell = (props) => {
        return (
            <Link to={`/carrier/${props.rowData.carrierMcNumber}/details`} target={ openLinksInNewTab ? "_blank" : "" } className={classes.link}>{props.rowData.carrierName}</Link>
        );
    };

    const CarrierRepCell = (props) => {
        const shipment = props.rowData;
        const email = shipment.carrierRepEmail ?
            shipment.carrierRepEmail.trim() === '' ? null : shipment.carrierRepEmail :
            null;
        return (
            <div>
                {
                    shipment.carrierRep &&
                    <span className={classes.carrierRepLabel}>{ shipment.carrierRep }</span>
                }
                {
                    email &&
                    <Tooltip title={`Send email to: ${email}`}>
                        <a href={'mailto:' + email}>
                            <Email className={classes.emailIcon} />
                        </a>
                    </Tooltip>
                }
            </div>
        );
    };

    const GridColumns = [
        // Make the column false for the filter later, if data visibility has
        // been limited due to security.
        !limitDataVisibility && {
            field: "associateSystemId",
            title: 'Rep',
            component: (props) => <AgentTooltipComponent content={props.rowData?.associateSystemId} agentId={props.rowData?.associateId}/>,
            styles: {
                width: 50
            },
            dataType: 'component'
        },
        {
            field: 'shipmentBolNumber',
            title: 'Ship #',
            component: BolNumberLinkCell(limitDataVisibility ? agencyId : null),
            styles: {
                width: 70
            },
            dataType: 'component'
        },
        {
            field: 'carrierName',
            title: 'Carrier Name',
            component: CarrierNameLinkCell,
            styles: {
                width: 270
            },
            dataType: 'component'
        },
        {
            field: 'shipmentBolDate',
            title: 'Pickup Date',
            styles: {
                width: 100
            },
            dataType: 'date'
        },
        {
            field: 'shipperFromCity',
            title: 'City From',
            styles: {
                width: 110
            },
            dataType: 'string'
        },
        {
            field: 'shipperFromState',
            title: 'State From',
            styles: {
                width: 60
            },
            dataType: 'string'
        },
        {
            field: 'consigneeToCity',
            title: 'City To',
            styles: {
                width: 110
            },
            dataType: 'string'
        },
        {
            field: 'consigneeToState',
            title: 'State To',
            styles: {
                width: 60
            },
            dataType: 'string'
        },
        {
            field: 'shipmentEquipmentType',
            title: 'Equipment Type',
            styles: {
                width: 110
            },
            dataType: 'string'
        },
        {
            field: 'carrierCost',
            title: 'Carrier Cost',
            styles: {
                width: 80
            },
            dataType: 'currency'
        },
        {
            field: 'carrierRep',
            title: 'Contact',
            component: CarrierRepCell,
            styles: {
                width: 110
            },
            dataType: 'component'
        },
        {
            field: 'carrierRepPhone',
            title: 'Phone',
            styles: {
                width: 110
            },
            dataType: 'string'
        },
        {
            field: 'carrierRepExperienceNotes',
            title: 'Experience Notes',
            styles: {
                width: '100%'
            },
            dataType: 'string'
        }
    ];

    return (
        <AppDenseGrid
            count={count || 0}
            data={data}
            orderBy={orderBy}
            order={order}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onSort={onSort}
            columns={GridColumns.filter(x => x)}
        />
    )
}

export default CarrierHistoryListing;